import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 구글, 레노버 등 글로벌 넘버원 社들과 손잡고 교육용 디바이스 출시",
  "| 크롬 OS, AR/VR 카메라, 태블릿 형태전환 등 다양한 기능 갖춰 교육현장에서의 적용 기대↑",
  "| 디지털 리터러시를 비롯, 비대면 교육영역에서 꼭 필요한 맞춤형 콘텐츠 탑재",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/19/xAgQVjRZ.png" alt="Figure1" />
      </div>
      <div className="desc">△스테이지파이브의 리틀카카오프렌즈 크롬북</div>
    </div>
    <p>
      <br />
      카카오 계열의 통신사업자인 스테이지파이브(대표 서상원)가 구글(CEO 순다르 피차이), 레노버코리아(대표이사 김윤호)과 함께 9월
      중 ‘리틀카카오프렌즈 크롬북’을 출시한다. 스테이지파이브의 키즈 전용디바이스 출시는 이번이 처음이 아니다. ‘카카오 키즈워치’,
      ‘리틀카카오프렌즈폰’, ‘신비폰’, ‘반려로봇 KEBBI’에 이른 다섯 번째 키즈 전용 디바이스로, 이번엔 교육에 초점을 맞췄다.
      <br />
      <br />
      리틀카카오프렌즈 크롬북은 교육현장에 즉시 도입 가능한 포터블 통신 디바이스로, 터치 디스플레이를 360도 회전하여 펜과 함께
      태블릿 PC로도 활용할 수 있어 활용도가 높다. 듀얼 카메라를 탑재하여 AR/VR을 자유롭게 체험할 수 있게 한 것도 특장점이다.
      더불어, 방수 기능 키보드를 적용하였고 미 국방성 내구성 테스트에서도 합격점을 받는 등 일선 교육 현장에서 사용하기에
      최적화되어 있다. ‘자이로 스코프 센서’를 활용해 메타버스를 비롯, 실감형 콘텐츠를 활용할 수 있게 설계한 것도 차별화 포인트다.
      <br />
      <br />
      여기에 더해 리틀카카오프렌즈 크롬북은 학생들의 디바이스를 관제해야 하는 교사들의 목소리를 반영하여 CEU(Chrome Education
      Upgrade) 라이선스를 기본으로 제공한다. 이를 통해 교사가 담당하고 있는 학생들의 리틀카카오프렌즈 크롬북 전체를 손쉽게 핸들링
      할 수 있는 것이 가장 큰 특장점이라 할 수 있다. 가령 수업에 필요한 앱 설치나 유해 앱/사이트 차단, 기기 포맷 등을 교사 스스로
      관제 할 수 있는 것이다. 뿐만 아니라, 교사 대상으로 리틀카카오프렌즈 크롬북 활용방법과 수업사례 교육도 제공한다. 기술적 문제
      발생 시에는 즉각 지원이 가능하도록 별도의 토탈 케어 콜센터를 운영할 예정이다.
      <br />
      <br />
      리틀카카오프렌즈 크롬북의 출시는 실제 현장에서 보다 체계적인 스마트 교육의 기반을 마련하는데 이바지할 것으로 보인다. 이를
      통해 선생님과 학생이 교육, 학습 역량을 최대한 발휘할 수 있도록 도와 교육격차를 줄이는 조력자 역할을 할 것으로 기대된다.
      <br />
      <br />
      스테이지파이브는 디바이스 공급과 AS 등 하드웨어적인 측면에 기울어 있었던 기존 교육용 디바이스 시장의 패러다임을 바꾸기 위해
      리틀카카오프렌즈 크롬북과 연계된 캠페인도 준비중이다. 특히, 비대면 교육으로 전환되며 그 중요성이 부각되고 있는 디지털
      리터러시 콘텐츠를 개발 및 적용하여 지역별, 계층별 디지털 활용도 격차를 줄이고 윤리적인 문제를 해결할 예정이다. 더불어 4차
      산업혁명 시대에 주목받고 있는 IT 분야에 주목하여 학생들을 위한 진로 체험이나 멘토링 프로그램을 운영 지원할 로드맵을 그리고
      있다. 기타 디지털기기 활용 교육, AI융합 인재 역량 교육 등도 기획에 착수한 상태다.
      <br />
      <br />
      해당 사업을 총괄하고 있는 스테이지파이브 이세연 이사는 “단순히 교육용 디바이스 공급에 그치는 기존 교육시장의 패러다임을
      변화시키기 위해 구글과 함께 손잡고 크롬 생태계 확장에 기여하고자 한다. 이번에 출시될 리틀카카오프렌즈 크롬북을 통해
      교육분야에서도 다양한 협력 기회를 모색할 수 있을 것이라 기대하고 있다.”고 밝혔다.
      <br />
      <br />
      <br />
    </p>
  </div>
)

const press15 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 8월 27일 즉시 배포 가능"
      pageInfo="*총 2매"
      title="“리틀카카오프렌즈와 크롬이 만났다!”<br/>스테이지파이브, ‘리틀카카오프렌즈 크롬북’ 출시 "
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press15
